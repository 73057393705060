import { Component, OnInit } from '@angular/core';
import {NgbModal, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ps-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent implements OnInit {

  FollowUps = [
    {
      name: 'Notification dated 10.08.2022 regarding addition for Second Phase date-Sheet for B.Sc. (Honours) Polymer Science & Food Technology Choice Based Credit System (CBCS-LOCF) Semester Examinations August-2022 meant for Part-3 (VI Semester) & Part-2 (IV Semester) students alongwith this only Ex-students, Essential Repeaters and Improvement cases of Part-1 (II Semester) shall also appear as per rule (Admission in 2019 & 2020) ',
      created_at: '15/08/2022 01:39:24',
      status: 'Active'
    },
    {
      name: 'Notification dated 10.08.2022 regarding Filling of the examination forms for Compulsory Test in Hindi (CTH) for Choice Based Credit System (CBCS) for Under Graduate Semester Examinations August-2022',
      created_at: '14/08/2022 13:39:12',
      status: 'In Active'
    },
    {
      name: 'Regarding GE Paper in Indian Economy I Exam notification: Notification dated 05.08.2022 regarding additon of GE Paper in Indian Economy I Exams August-2022 ',
      created_at: '15/08/2022 02:39:42',
      status: 'In Active'
    },
  ];
  page = 4;
  advancePage = 1;
  currentPage = 3;
  isDisabled = true;
  closeResult: string;

  constructor(config: NgbPaginationConfig,
              private modalService: NgbModal) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  ngOnInit() {
  }

  modalType: any;

  submit() {
    this.modalType = 'ADD';
  }

  update() {
    this.modalType = 'UPDATE';
  }

  openLg(content) {
    this.modalService.open(content, {size: 'lg'});
  }

  toggleDisabled() {
    this.isDisabled = !this.isDisabled;
  }

}
