import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})

export class NavService {

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebarMainMenu = false;
      this.collapseSidebarSecondMenu = true;
    }
  }

  public openclass: boolean = false;
  public screenWidth: any;

  public collapseSidebarMainMenu = false;
  public collapseSidebarSecondMenu = false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth >= 991;

  public fullScreen = false;

  MENUITEMS: Menu[] = [
    {
      title: 'General', path: '/general/home', icon: 'pe-7s-home', type: 'sub', active: true, children:
        [
          {
            title: 'General', type: 'sub', active: true, children: [
              {path: '/general/home', title: 'Home', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Centers', path: '/centers/home', icon: 'pe-7s-culture', type: 'sub', active: false, children:
        [
          {
            title: 'Centers', type: 'sub', active: true, children: [
              {path: '/centers/home', title: 'Home', type: 'link'},
              {path: '/centers/add', title: 'Add Center', type: 'link'},
              {path: '/centers/list', title: 'List Centers', type: 'link'},
              {path: '/centers/share-percentage', title: 'Share Percentage', type: 'link'},
              {path: '/centers/ia-aw-marks', title: 'IA - AW Marks', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Students', path: '/students/home', icon: 'fas fa-user-graduate', type: 'sub', active: false, children:
        [
          {
            title: 'Student', type: 'sub', active: true, children: [
              {path: '/students/home', title: 'Home', type: 'link'},
              /*{path: '/students/follow-up-stages', title: 'Follow Up Stages', type: 'link'},
              {path: '/students/student-enquiries', title: 'Student Enquiries', type: 'link'},*/
              {path: '/students/direct-students', title: 'Direct Students', type: 'link'},
              {path: '/students/center-students', title: 'Center Students', type: 'link'},
              {path: '/students/verify-students', title: 'Verify Students', type: 'link'},
              {path: '/students/assign-enrollment', title: 'Assign Enrollment', type: 'link'},
              {path: '/students/assign-roll-no', title: 'Assign Roll No.', type: 'link'},
              {path: '/students/all-students', title: 'All Students', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Sessions', path: '/sessions/home', icon: 'fas fa-sliders-h', type: 'sub', active: false, children:
        [
          {
            title: 'Sessions', type: 'sub', active: true, children: [
              {path: '/sessions/home', title: 'Home', type: 'link'},
              {path: '/sessions/add-session', title: 'Add Session', type: 'link'},
              {path: '/sessions/list-session', title: 'List Session', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Modules', path: '/modules/home', icon: 'fas fa-bookmark', type: 'sub', active: false, children:
        [
          {
            title: 'Modules', type: 'sub', active: true, children: [
              {path: '/modules/home', title: 'Home', type: 'link'},
              {path: '/modules/programs', title: 'Programs', type: 'link'},
              {path: '/modules/courses', title: 'Courses', type: 'link'},
              {path: '/modules/branches', title: 'Branches', type: 'link'},
              {path: '/modules/mappings', title: 'Mappings', type: 'link'},
              {path: '/modules/subjects', title: 'Subjects', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Dept.', path: '/departments/home', icon: 'fas fa-chalkboard', type: 'sub', active: false, children:
        [
          {
            title: 'Departments', type: 'sub', active: true, children: [
              {path: '/departments/home', title: 'Home', type: 'link'},
              {path: '/departments/add-department', title: 'Add Department', type: 'link'},
              {path: '/departments/list-department', title: 'List Departments', type: 'link'},
              /*{path: '/departments/add-hod', title: 'Add HOD', type: 'link'},
              {path: '/departments/list-hod', title: 'List HODs', type: 'link'},*/
              {path: '/departments/add-lecturer', title: 'Add Lecturer', type: 'link'},
              {path: '/departments/list-lecturer', title: 'List Lecturers', type: 'link'},
              {path: '/departments/add-lecture', title: 'Add Video Lecture', type: 'link'},
              {path: '/departments/list-lecture', title: 'List Video Lecture', type: 'link'},
              {path: '/departments/add-slms', title: 'Add SLM', type: 'link'},
              {path: '/departments/list-slms', title: 'List SLMs', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Exams', path: '/exams/home', icon: 'pe-7s-note2', type: 'sub', active: false, children:
        [
          {
            title: 'Exams', type: 'sub', active: true, children: [
              {path: '/exams/home', title: 'Home', type: 'link'},
              {path: '/exams/schedule-exam', title: 'Schedule Exam', type: 'link'},
              {path: '/exams/add-exam', title: 'Add Exam', type: 'link'},
              /*{path: '/exams/admit-card', title: 'Admit Card', type: 'link'},
              */{path: '/exams/appearing-candidates', title: 'Appearing Candidates', type: 'link'},
              /*{path: '/exams/add-question-paper', title: 'Add Question Paper', type: 'link'},
              {path: '/exams/list-question-paper', title: 'List Question Papers', type: 'link'},
              */{path: '/exams/student-exam', title: 'Student Exam', type: 'link'},
              {path: '/exams/student-examination', type: 'link'},
              {path: '/exams/add-section', type: 'link'},
              {path: '/exams/add-question', type: 'link'},

            ]
          }
        ],
    },
    {
      title: 'Results', path: '/results/home', icon: 'pe-7s-study', type: 'sub', active: false, children:
        [
          {
            title: 'Results', type: 'sub', active: true, children: [
              {path: '/results/home', title: 'Home', type: 'link'},
              {path: '/results/schedule-result', title: 'Schedule Result', type: 'link'},
              {path: '/results/student-result', title: 'Student Result', type: 'link'},
              {path: '/results/revaluation', title: 'Revaluation', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Docs', path: '/docs/home', icon: 'pe-7s-study', type: 'sub', active: false, children:
        [
          {
            title: 'Docs', type: 'sub', active: true, children: [
              {path: '/docs/home', title: 'Home', type: 'link'},
              {path: '/docs/marksheet', title: 'Marksheet', type: 'link'},
              {path: '/docs/original-degree', title: 'Original Degree', type: 'link'},
              {path: '/docs/migration', title: 'Migration', type: 'link'},
              {path: '/docs/transcript', title: 'Transcript', type: 'link'},
              /*{path: '/docs/revaluation', title: 'Revaluation', type: 'link'},*/
              /*{path: '/docs/correction', title: 'Correction', type: 'link'},*/
            ]
          }
        ],
    },
    {
      title: 'Accounts', path: '/accounts/home', icon: 'fas fa-credit-card', type: 'sub', active: false, children:
        [
          {
            title: 'Accounts', type: 'sub', active: true, children: [
              {path: '/accounts/home', title: 'Home', type: 'link'},
              {path: '/accounts/students', title: 'Students', type: 'link'},
              {path: '/accounts/center-admissions', title: 'Center Admissions', type: 'link'},
              {path: '/accounts/center-re-registrations', title: 'Center Re-Registrations', type: 'link'},
              {path: '/accounts/direct-admissions', title: 'Direct Admissions', type: 'link'},
              {path: '/accounts/direct-re-registrations', title: 'Direct Re-Registrations', type: 'link'},
              {path: '/accounts/examinations', title: 'Examinations', type: 'link'},
              {path: '/accounts/revalutions', title: 'Revaluations', type: 'link'},
              {path: '/accounts/marksheets', title: 'Marksheets', type: 'link'},
              {path: '/accounts/original-degree', title: 'Original Degree', type: 'link'},
              {path: '/accounts/migrations', title: 'Migrations', type: 'link'},
              {path: '/accounts/transcripts', title: 'Transcripts', type: 'link'},
              {path: '/accounts/centers', title: 'Centers - Add Money', type: 'link'},
              {path: '/accounts/centers-transactions', title: 'Centers - Transactions', type: 'link'},
              {path: '/accounts/centers-receipts', title: 'Centers - Receipts', type: 'link'},
              {path: '/accounts/centers-report', title: 'Centers - Report', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Access', path: '/access/home', icon: 'fas fa-globe', type: 'sub', active: false, children:
        [
          {
            title: 'Accessibility', type: 'sub', active: true, children: [
              {path: '/access/home', title: 'Home', type: 'link'},
              {path: '/access/roles', title: 'Roles', type: 'link'},
              {path: '/access/permissions', title: 'Permissions', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Notify', path: '/notify/home', icon: 'fas fa-bell', type: 'sub', active: false, children:
        [
          {
            title: 'Notify', type: 'sub', active: true, children: [
              {path: '/notify/home', title: 'Home', type: 'link'},
              {path: '/notify/add', title: 'Add Notification', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Support', path: '/support/home', icon: 'pe-7s-headphones', type: 'sub', active: false, children:
        [
          {
            title: 'Support', type: 'sub', active: true, children: [
              {path: '/support/home', title: 'Home', type: 'link'},
              {path: '/support/admission-tickets', title: 'Admission Tickets', type: 'link'},
              {path: '/support/re-registration-tickets', title: 'Re-Registration Tickets', type: 'link'},
              {path: '/support/examination-tickets', title: 'Examination Tickets', type: 'link'},
              {path: '/support/marksheet-tickets', title: 'Marksheet Tickets', type: 'link'},
              {path: '/support/transcript-tickets', title: 'Transcript Tickets', type: 'link'},
              {path: '/support/revaluation-tickets', title: 'Revalution Tickets', type: 'link'},
              {path: '/support/migration-tickets', title: 'Migration Tickets', type: 'link'},
              {path: '/support/original-degree-tickets', title: 'Original Degree Tickets', type: 'link'},
              {path: '/support/e-learning-tickets', title: 'E-Learning Tickets', type: 'link'},
              {path: '/support/other-tickets', title: 'Other Tickets', type: 'link'}
            ]
          }
        ],
    },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
