import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

  static get UNIVERSITY_URL(): string {
    return environment.appUrl + 'university/';
  }

  static get LOGIN(): string {
    return AppUrl.UNIVERSITY_URL + 'login';
  }

  static get SELF(): string {
    return AppUrl.UNIVERSITY_URL + 'self';
  }

  static UPDATE_SELF(): string {
    return AppUrl.UNIVERSITY_URL + 'update-self';
  }

  static LOGOUT(): string {
    return AppUrl.UNIVERSITY_URL + 'logout';
  }

  static FILE_UPLOAD(): string {
    return AppUrl.UNIVERSITY_URL + 'file-upload';
  }

  static UPDATE_USER_PASSWORD(): string {
    return AppUrl.UNIVERSITY_URL + 'update-user-password';
  }

  static COURSES(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'course/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'course';
    }
  }

  static STUDENT(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'student/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'student';
    }
  }

  static ADD_STUDENT(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'add-student/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'add-student';
    }
  }

  static RESEND_OTP_STUDENT(): string {
    return AppUrl.UNIVERSITY_URL + 'resend-otp-student';
  }

  static VERIFY_OTP_STUDENT(): string {
    return AppUrl.UNIVERSITY_URL + 'verify-otp-student';
  }

  static STATES(): string {
    return AppUrl.UNIVERSITY_URL + 'states';
  }

  static CENTER(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'center/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'center';
    }
  }

  static CENTER_PASSWORD(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'center-password/' + id;
    }
  }

  static CENTER_STATUS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'center-status/' + id;
    }
  }

  static SESSIONS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'session/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'session';
    }
  }

  static DEFAULT_SESSION(): string {
    return AppUrl.UNIVERSITY_URL + 'default-session';
  }

  static GET_SHARE_PERCENTAGE(): string {
    return AppUrl.UNIVERSITY_URL + 'share-percentage';
  }

  static UPDATE_SHARE_PERCENTAGE(user_id, session_id): string {
    return AppUrl.UNIVERSITY_URL + 'update-share-percentage/' + user_id + '/' + session_id;
  }

  static PERSONAL_INFO(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'personal-info/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'personal-info';
    }
  }

  static TRANSLATE_TEXT(): string {
    return AppUrl.UNIVERSITY_URL + 'translate-text';
  }

  static PERSONAL_INFO_PHOTOGRAPH_UPDATE(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'personal-info-photograph/' + id;
    }
  }

  static PERSONAL_INFO_GOVT_PHOTO_ID_UPDATE(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'personal-info-govt-photo-id-proof/' + id;
    }
  }

  static ADDRESS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'address/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'address';
    }
  }

  static ACADEMIC_DETAILS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'academic-details/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'academic-details';
    }
  }

  static ACADEMIC_DETAILS_TENTH_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'academic-details-tenth-certificate/' + id;
    }
  }

  static ACADEMIC_DETAILS_TWELTH_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'academic-details-twelth-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'more-qualification/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'more-qualification';
    }
  }

  static MORE_QUALIFICATION_GRADUATION_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'more-qualification-graduation-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION_POST_GRADUATION_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'more-qualification-post-graduation-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION_OTHER_QUALIFICATION_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'more-qualification-other-qualification-certificate/' + id;
    }
  }

  static PROGRAMS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'program/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'program';
    }
  }

  static PCB_MAPPINGS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'pcb-mapping/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'pcb-mapping';
    }
  }

  static COURSE_DETAILS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'course-details/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'course-details';
    }
  }

  static COURSE_CURRICULUM(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'course-curriculum/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'course-curriculum';
    }
  }
  static PROFILE_IMAGE(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL+ 'profile-image/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL+ 'profile-image';
    }
  }
}
